import { QueryResult } from './QueryResult';
import { ComplexQueryOutline } from './outline/ComplexQueryOutline';
import { LiveQueryOutline } from './outline/LiveQueryOutline';
import { LiveDataQueryResults } from './LiveDataQueryResults';
import { InjectionToken } from '@angular/core';

export const QueryExecutorToken = new InjectionToken<IQueryExecutor>('query-executor');

export interface IQueryExecutor {
  doComplexQuery(outline: ComplexQueryOutline): Promise<QueryResult<number>>;
  doLiveQuery(outline: LiveQueryOutline): Promise<QueryResult<number>>;
  doLiveDataQuery(outline: LiveQueryOutline): Promise<LiveDataQueryResults>;
  cancelLiveQuery(): void;
  cancelNodeDataQuery(): void;
  cancelComplexQuery(): void;
}

export class QueryExecutorConfiguration {
  constructor(
    public simpleQueryUrl: string,
    public complexQueryUrl: string,
    public liveQueryUrl: string,
    public liveDataQueryUrl: string
  ) {}
}
