import * as angular from 'angular'; // Automatically added
import { DataType } from '@angularjs/or/data/SensorNodeDataType';
import { QueryResult } from '@angularjs/or/api/query/QueryResult';
import { IQueryExecutor } from '@angularjs/or/api/query/IQueryExecutor';
import { Pair } from '@angularjs/or/util/Pair';
import { IQueryOutlineBuilder } from '@angularjs/or/api/query/outline/IQueryOutlineBuilder';
import { IMetricContext } from '@angularjs/or/api/query/outline/context/IContext';
import { StringUtils } from '@angularjs/or/util/StringUtils';
import { TimeNavigator } from '@angularjs/or/time/TimeNavigator';
import { ChartData } from '@angularjs/or/view/ChartData';
import { RenderableChart } from '@angularjs/or/view/RenderableChart';
import { DataPoint } from '@angularjs/or/view/DataPoint';
import { IObservable, IObservableModifiable } from '@angularjs/or/util/IObservable';
import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { CopyToClipboardService } from '@angularjs/or/angular/services/CopyToClipboardService';

export class OrMetricWidgetController {
  private isRefreshRedundant = false;
  private data: ChartData;

  public metric;
  public isActive: () => boolean;
  public setActive: () => void;
  public canZoomIn = false;
  public canZoomOut = false;
  public canShiftTimeframe = false;

  public isBusy = true;
  public chartContainer;
  public chart;
  public timeframeLabel: string;

  constructor(
    private timeNavigator: TimeNavigator,
    private queryExecutor: IQueryExecutor,
    private outlineBuilder: IObservable<IQueryOutlineBuilder>,
    private metricContext: IObservableModifiable<IMetricContext>,
    private buildingService: IBuildingService,
    private copyToClipboardService: CopyToClipboardService,
    public $element,
    private $scope,
    private $window
  ) {}

  public $onInit() {
    this.outlineBuilder.onChange((value: IQueryOutlineBuilder) => this.updateData(value));

    this.buildingService.getCurrentBuilding().then((building) => {
      this.timeNavigator.reset(building.timeZone);
      this.timeNavigator.setDates(this.metricContext.value().dates);
      this.timeNavigator.setResolution(this.metricContext.value().resolution);

      this.timeframeLabel = StringUtils.truncate(this.timeNavigator.getLabel(), 15);

      if (this.isActive()) {
        this.metricContext.change((value) => {
          value.dates = this.timeNavigator.getDates();
          value.resolution = this.timeNavigator.getResolution();
        });
      }
    });

    angular.element(this.$window.document).on('keydown', (e) => {
      if (e.keyCode == 67 && e.ctrlKey && this.data != null && this.isActive()) {
        this.copyToClipboard();
      }
    });

    this.$scope.$on('$destroy', () => angular.element(this.$window.document).off('keydown'));
  }

  public copyToClipboard() {
    if (this.data != null) {
      const dataType = this.metricContext.value().dataType.toString().toLowerCase();
      this.copyToClipboardService.copy(this.data.asCsvData(Pair.of('Time', this.metric.label)));
      alert('Copied data to your clipboard! Paste into a spreadsheet to import the data.');
    }
  }

  public updateData(value: IQueryOutlineBuilder) {
    if (!this.isActive() || this.isRefreshRedundant) {
      this.update();
      return;
    }

    const outline = value.buildNavigationOutline();
    this.isBusy = true;
    this.queryExecutor.doComplexQuery(outline).then((result: QueryResult<number>) => {
      this.$scope.$apply(() => {
        this.update();
        this.isBusy = false;
        this.data = this.convertToChart(result);
        this.render(this.data);
      });
    });
  }

  public render(chart: ChartData) {
    const container = angular.element(this.$element).find('svg')[0];
    const renderableChart = new RenderableChart(
      chart,
      container,
      true,
      () => this.timeNavigator.canSelect(),
      (points) => this.select(points),
      (amount) => this.timeNavigator.canZoom(amount),
      (amount) => this.zoom(amount)
    );
    renderableChart.render();
  }

  private update() {
    this.timeframeLabel = StringUtils.truncate(this.timeNavigator.getLabel(), 15);
    this.isRefreshRedundant = false;
    this.canZoomIn = this.timeNavigator.canZoom(1);
    this.canZoomOut = this.timeNavigator.canZoom(-1);
    this.canShiftTimeframe = this.timeNavigator.canShift();
  }

  public setActiveAndRefresh() {
    this.setActive();
    this.metricContext.change((value) => (value.dataType = DataType.fromString(this.metric.name)));
  }

  public zoomIn() {
    this.zoom(1);
  }

  public zoomOut() {
    this.zoom(-1);
  }

  private zoom(amount: number) {
    this.timeNavigator.zoom(amount);
    this.metricContext.change((value) => {
      value.dates = this.timeNavigator.getDates();
      value.resolution = this.timeNavigator.getResolution();
    });
  }

  public select(points: DataPoint[]) {
    this.isRefreshRedundant = true;
    this.timeNavigator.select(points.map((point) => point.x));
    this.metricContext.change((value) => {
      value.dates = this.timeNavigator.getDates();
      value.resolution = this.timeNavigator.getResolution();
    });
  }

  public shiftTimeframeForward() {
    this.shiftTimeframe(1);
  }

  public shiftTimeframeBack() {
    this.shiftTimeframe(-1);
  }

  private convertToChart(result: QueryResult<number>): ChartData {
    const dataType = this.metricContext.value().dataType;
    const chart = ChartData.fromQueryResult(result);
    chart.values.forEach((point) => {
      point.isActive = this.timeNavigator.isActive(point.x);
      point.xLabel = this.timeNavigator.getPointLabel(point.x);
      point.yLabel = dataType.format(point.y, result.suffix);
    });

    return chart;
  }

  private shiftTimeframe(amount: number) {
    this.timeNavigator.shift(amount);
    this.metricContext.change((value) => {
      value.dates = this.timeNavigator.getDates();
      value.resolution = this.timeNavigator.getResolution();
    });
  }

  private resetTimeframe(): void {
    this.queryExecutor.cancelComplexQuery();
    this.queryExecutor.cancelLiveQuery();
    this.select([]);
    this.updateData(this.outlineBuilder.value());
  }
}
