import { QueryResult } from './../api/query/QueryResult';
import { IQueryExecutor } from '../api/query/IQueryExecutor';
import { ComplexQueryOutline } from '../api/query/outline/ComplexQueryOutline';
import { LiveQueryOutline } from '../api/query/outline/LiveQueryOutline';
import { Cache } from '../util/Cache';
import { LiveDataQueryResults } from '@angularjs/or/api/query/LiveDataQueryResults';

export class CachingQueryExecutor implements IQueryExecutor {
  private complexQueryCache: Cache<ComplexQueryOutline, Promise<QueryResult<number>>>;
  private cachePath: string;
  private lastOutline;

  constructor(private underlyingQueryExecutor: IQueryExecutor, private ttl: number, scope: any) {
    this.complexQueryCache = new Cache<ComplexQueryOutline, Promise<QueryResult<number>>>(
      (outline) => this.underlyingQueryExecutor.doComplexQuery(outline),
      ttl
    );
    scope.$on('$locationChangeSuccess', () => {
      if (location.pathname != this.cachePath) {
        this.complexQueryCache.clear();
      }
    });
  }

  public doComplexQuery(outline: ComplexQueryOutline): Promise<QueryResult<number>> {
    this.cachePath = location.pathname;
    this.lastOutline = outline;
    return this.complexQueryCache.getValue(outline);
  }

  public doLiveQuery(outline: LiveQueryOutline): Promise<QueryResult<number>> {
    return this.underlyingQueryExecutor.doLiveQuery(outline);
  }

  public cancelLiveQuery(): void {
    this.underlyingQueryExecutor.cancelLiveQuery();
  }

  public cancelNodeDataQuery(): void {
    this.underlyingQueryExecutor.cancelNodeDataQuery();
  }

  doLiveDataQuery(outline: LiveQueryOutline): Promise<LiveDataQueryResults> {
    return this.underlyingQueryExecutor.doLiveDataQuery(outline);
  }

  cancelComplexQuery(): void {
    this.underlyingQueryExecutor.cancelComplexQuery();
  }
}
