<div class="or-building-tile">
  <a [attr.data-cy]="'building-tile-link-' + details.id" class="or-building-tile-link" [href]="produceBuildingUrl(details) | async">
    <div [attr.data-cy]="'building-tile-image-' + details.id" class="or-building-tile-image" [ngStyle]="produceBuildingThumbnailStyle(details)"></div>
    <div class="or-building-tile-body">
      <div class="or-building-tile-summary" [title]="produceBuildingAddressString(details.address)">
        <h1 [attr.data-cy]="'building-name-'+ details.id" class="or-building-tile-name" [textContent]="details.name"></h1>
        <p [attr.data-cy]="'building-address-' + details.id" class="or-building-tile-address" [textContent]="produceBuildingAddressString(details.address)"></p>
        <span
          [attr.data-cy]="'building-status-' + details.id"
          class="or-building-tile-status"
          [ngClass]="produceBuildingStateClass(details.realTimeBuildingData?.state)"
        >
        </span>
      </div>
      <div class="or-building-tile-real-time-data">
        <ul class="or-building-tile-metrics">
          <li class="or-building-tile-metric" [ngClass]="produceOfflineNodesClass()" title="Energy Consumption">
            <span class="or-icon or-small or-inverse or-icon-energy or-building-tile-metric-icon"></span>
            <span [attr.data-cy]="'building-energy-consumption-' + details.id"class="or-building-tile-metric-value">
              {{ details.realTimeBuildingData?.energyConsumption.toFixed(2) || '' }}kWh
            </span>
          </li>
          <li class="or-building-tile-metric" [ngClass]="produceOfflineNodesClass()" title="Sensor Nodes">
            <span class="or-icon or-small or-inverse or-icon-node or-building-tile-metric-icon"></span>
            <span
              [attr.data-cy]="'building-node-count-' + details.id"
              class="or-building-tile-metric-value"
            >
            {{ details.realTimeBuildingData?.nodeCount || '-' }}
            </span>
          </li>
          <li
            class="or-building-tile-metric"
            [ngClass]="produceOfflineNodesClass()"
            title="Offline Sensor Nodes"
            *ngIf="hasFaultyNodes()"
          >
            <span class="or-icon or-small or-inverse or-icon-node-faulty or-building-tile-metric-icon"></span>
            <span
              [attr.data-cy]="'building-offline-node-count-' + details.id"
              class="or-building-tile-metric-value"
            >
            {{ details.realTimeBuildingData?.offlineNodeCount || '-' }}
            </span>
          </li>
          <li class="or-building-tile-metric" [ngClass]="produceOfflineNodesClass()" title="Occupancy">
            <span class="or-icon or-small or-inverse or-icon-users or-building-tile-metric-icon"></span>
            <span [attr.data-cy]="'building-occupancy-' + details.id"class="or-building-tile-metric-value">
              {{ details.realTimeBuildingData?.occupancy.toFixed(2) || '' }}%
            </span>
          </li>
        </ul>
      </div>
    </div>
  </a>
</div>
