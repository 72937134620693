import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { getNodeTypes } from '@app/shared/models/types/node-type';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { UserFriendlyDiscriminator } from '@app/shared/models/selectable.interface';

@Component({
  selector: 'app-nodes-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    SharedComponentsModule
  ],
  templateUrl: './nodes-filter.component.html',
  styleUrl: './nodes-filter.component.scss'
})
export class NodesFilterComponent {
  constructor() {}

  @Input()
  sortByFields: Record<string, string>;

  nodeTypes = getNodeTypes();

  @Input()
  filterForm: FormGroup;

  @Output()
  filterChange = new EventEmitter<NodeFilter>();

  @Output()
  copyNodesToClipboard = new EventEmitter<void>();

  @Output()
  refreshNodeListEmitter = new EventEmitter<void>();

  filter: Record<string, string> = {};

  protected readonly Object = Object;

  toggleSortOrder(): void {
    const asc = !this.filterForm.value.sortAsc;
    this.filterForm.get('sortAsc').setValue(asc);
    const nodeFilter = {
      filterCriteria: this.filter,
      sortCriteria: {
        field: this.filterForm.value.sortBy,
        asc: this.filterForm.value.sortAsc
      }
    };
    this.filterChange.emit(nodeFilter);
  }

  reloadNodes(): void {}

  copyToClipboard(): void {
    this.copyNodesToClipboard.emit();
  }

  refreshNodeList(): void {
    this.refreshNodeListEmitter.emit();
  }

  changeId(id: string): void {
    this.filter.id = id;
    const nodeFilter = {
      filterCriteria: this.filter,
      sortCriteria: {
        field: this.filterForm.value.sortBy,
        asc: this.filterForm.value.sortAsc
      }
    };
    this.filterChange.emit(nodeFilter);
  }

  changeAddress(address: string): void {
    this.filter.address = address;
    const nodeFilter = {
      filterCriteria: this.filter,
      sortCriteria: {
        field: this.filterForm.value.sortBy,
        asc: this.filterForm.value.sortAsc
      }
    };
    this.filterChange.emit(nodeFilter);
  }

  changeNodeType(type: string): void {
    this.filter.nodeType = type;
    const nodeFilter = {
      filterCriteria: this.filter,
      sortCriteria: {
        field: this.filterForm.value.sortBy,
        asc: this.filterForm.value.sortAsc
      }
    };
    this.filterChange.emit(nodeFilter);
  }

  changeSortBy(): void {
    const nodeFilter = {
      filterCriteria: this.filter,
      sortCriteria: {
        field: this.filterForm.value.sortBy,
        asc: this.filterForm.value.sortAsc
      }
    };
    this.filterChange.emit(nodeFilter);
  }

  getUserFriendlyDiscriminator(discriminator: string): UserFriendlyDiscriminator {
    return UserFriendlyDiscriminator[discriminator];
  }
}

export interface NodeFilter {
  filterCriteria: Record<string, string>;
  sortCriteria: any;
}
