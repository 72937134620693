export class Unit {
  constructor(
    public value: Unit_,
    public format: (number) => string = (v) => v.toString(),
    public minimum: number = 0,
    public maximum?: number,
    public step?: number,
    public options?: number[]
  ) {}

  public static NONE = new Unit('NONE');
  public static PERCENTAGE = new Unit('PERCENTAGE', (v) => v + '%', 0, 100);
  public static LUX = new Unit('LUX', (v) => v + 'lx');
  public static KILOWATT_HOUR = new Unit('KILOWATT_HOUR', (v) => v + 'kWh');
  public static MINUTES = new Unit('MINUTES', (v) => v + 'm');
  public static SECONDS = new Unit('SECONDS', (v) => v + 's');
  public static SCENE_TYPE = new Unit('SCENE_TYPE', (v) => 'Scene ' + v, 0, 7, 1);
  public static DWELL_LENGTH = new Unit(
    'DWELL_LENGTH',
    (v) => {
      if (v == 0.5 || v == 1) {
        return v + 'minute';
      }
      if (v < 60 || v == 90) {
        return v + ' minutes';
      }
      if (v == 60) {
        return '1 hour';
      }
      if (v < 1440) {
        return v / 60 + 'hours';
      }
      if (v == 1440) {
        return '1 day';
      }
      return v / 1440 + 'days';
    },
    null,
    null,
    null,
    [0.5, 1, 2, 3, 5, 7, 10, 15, 20, 25, 30, 45, 60, 90, 120, 180, 240, 360, 480, 720, 1440, 10080, 525600]
  );
  public static LIGHT_LEVEL = new Unit(
    'LIGHT_LEVEL',
    (v) => v + '%',
    null,
    null,
    null,
    [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12.5, 14, 16, 18, 20, 22, 25, 28, 32, 36, 40, 45, 50, 55, 60, 70, 80, 90,
      100
    ]
  );
  public static PERSONALITY_TYPE = new Unit(
    'PERSONALITY_TYPE',
    (v) => {
      return [
        'Open Floor',
        'Open Floor Saver',
        'Corridor',
        'Closed Office',
        'Amenities',
        'Store: Room',
        'Breakout',
        'Security Light',
        'Demo',
        'All On Always',
        'Extreme Saver',
        '100/50/0',
        'Desklamp',
        'Motion Only',
        'Ripples',
        'Wallwash'
      ][v];
    },
    0,
    15,
    1
  );

  public static all() {
    return [
      Unit.NONE,
      Unit.PERCENTAGE,
      Unit.LUX,
      Unit.KILOWATT_HOUR,
      Unit.MINUTES,
      Unit.SECONDS,
      Unit.SCENE_TYPE,
      Unit.DWELL_LENGTH,
      Unit.LIGHT_LEVEL,
      Unit.PERSONALITY_TYPE
    ];
  }

  public toString() {
    return this.value;
  }
}

type Unit_ =
  | 'NONE'
  | 'PERCENTAGE'
  | 'LUX'
  | 'KILOWATT_HOUR'
  | 'MINUTES'
  | 'SECONDS'
  | 'SCENE_TYPE'
  | 'DWELL_LENGTH'
  | 'LIGHT_LEVEL'
  | 'PERSONALITY_TYPE';
